import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Nav,
  Row,
  Col,
  Tab,
  Form,
  Button as BootstrapButton,
} from "react-bootstrap";
import validator from "validator";
import {
  getUserDetails,
  changePassword,
  changeUser,
  logout,
  contactUs,
} from "../service/contentService";
import {
  finishSubscription,
  deleteAccount,
  getSSS,
  getProfileImages,
  addProfileImage,
} from "../service/customerService";
import { useAuth } from "../components/AuthContext";
import useModal from "../components/hooks/useModal";
import HooxModal, { HOOX_MODAL } from "../components/HooxModal";
import styled from "styled-components";

import profileIcon from "../assets/img/profileIcon.svg";
import billingfoIcon from "../assets/img/billinginfo.svg";
import packagesIcon from "../assets/img/packages.svg";
import contactUsIcon from "../assets/img/contactus.svg";
import faqIcon from "../assets/img/faq.svg";
import logoutIcon from "../assets/img/logout.svg";
import chngPsw from "../assets/img/password-lock-svgrepo-com.svg";

import ReactInputMask from "react-input-mask";
import { Helmet } from "react-helmet";
import Messages from "../components/Messages";
import ChangePassword from "../components/userDetails/ChangePassword";
import MySubscription, {
  SubsFormButtons,
} from "../components/userDetails/MySubscription";
import { cities, subjects } from "../util/constants";

const NavMenuItemStyled = styled(Nav.Item)`
  & .nav-link {
    color: #fff;
    border-radius: 20px;
  }

  & .nav-link.active {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
  }

  &:hover {
    color: #fff;
  }
`;

const NavUserNameBoxStyled = styled("div")`
  color: #fff;
  border-bottom: 1px solid #12d6df;
  margin-bottom: 17px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TabTitle = styled("div")`
  font-size: 23px;
  font-weight: 500;
`;

const TabContent = styled(Tab.Content)`
  color: #fff;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 20px;
  padding: 30px;
  font-size: 13px;
`;

const FormControl = styled(Form.Control)`
  background-color: rgb(0 7 58) !important;
  border-radius: 7px !important;
  resize: none;
  height: 40px;
  font: normal normal normal 14px GilroyLight;
  &:focus-visible {
    outline: rgba(0, 0, 0, 0.8) !important;
  }
`;

const FormControlMask = styled(ReactInputMask)`
  background-color: rgb(0 7 58) !important;
  border-radius: 7px !important;
  resize: none;
  height: 40px;
  color: #dfdee2;
  font: normal normal normal 14px GilroyLight;
  &:focus-visible {
    outline: rgba(0, 0, 0, 0.8) !important;
  }
`;

const FormSelect = styled(Form.Select)`
  background-color: rgb(0 7 58) !important;
  border-radius: 7px !important;
  border: 1px solid rgba(18 214 223 / 30%);
  height: 40px;
  color: #dfdee2;
  font: normal normal normal 14px GilroyLight;

  &:focus {
    border-color: rgba(0, 0, 0, 0.8) !important;
  }
`;

const Line = styled("hr")`
  color: #12d6df;
  opacity: 1;
`;

/*
const FormCheckInput = styled("div")`
  & input {
    background-color: transparent;
    border: 2px solid #12d6df;
    width: 15px;
    height: 15px;

    &:focus {
      box-shadow: none;
    }

    &:checked[type="checkbox"] {
      background-color: #12d6df;
      border: 2px solid #12d6df;
    }
  }
`;
*/

export const InfoField = styled.p`
  display: block;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  border: 1px solid rgba(18 214 223 / 30%);
  width: 50%;
  padding: 10px;
`;

const Icon = styled("img")`
  margin-right: 16px;
`;

function UserDetails() {
  const { setUser } = useAuth();
  const { isShowing: isShowingSuccess, toggle: toggleSuccessModal } =
    useModal();
  const { isShowing: contactModalVisible, toggle: toggleContactModal } =
    useModal();
  const { isShowing: isShowingDelete, toggle: toggleDeleteModal } = useModal();
  const { isShowing: isShowingFinish, toggle: toggleFinishModal } = useModal();
  const { isShowing: isShowingAvatar, toggle: toggleAvatarModal } = useModal();
  const { isShowing: isShowingAccDeleted, toggle: toggleAccDeleted } =
    useModal();

  const { isShowing: isShowingError, toggle: toggleShowError } = useModal();

  const { user } = useAuth();

  //const { guid } = user;

  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = React.useState("");

  //const [userCredentials, setUserCredentials] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [contactMessage, setContactMessage] = useState("");
  const [contactSubject, setContactSubject] = useState(subjects[0]);
  const [contactModalType, setContactModalType] = useState(true);
  const [subscriptionModalType, setSubscriptionModalType] = useState(false);
  const [subscriptionModalText, setSubscriptionModalText] = useState(
    "Aboneliğinizi iptal etmek istediğinize emin misiniz?"
  );
  const [sss, setSss] = useState([]);
  const [edit, setEdit] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [avatarHover, setAvatarHover] = useState(false);

  const [isEdu, setIsEdu] = useState(false);

  const [refreshMessageList, setRefreshMessageList] = useState(false);

  useEffect(() => {
    //console.log("getUserDetails");
    getUserDetails()
      .then((response) => {
        setUserDetails(response.data);
        //console.log("getUserDetails.response.data:", response.data);
        const domain = response?.data?.email?.split("@")[1];
        setIsEdu(domain.toLowerCase().endsWith("edu.tr"));
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    getSSS().then((response) => {
      setSss(JSON.parse(response.data));
    });
  }, []);

  const handleChange = (e) => {
    const { value, id } = e.target;
    //console.log("handleChange : val: ", value, " | id: ", id);

    userDetails[id] = value;
    setUserDetails({ ...userDetails });
  };

  function logoutSubmit() {
    logout().then(() => {
      setUser({ userName: null, isLoggedIn: false });
      localStorage.clear();
      navigate("/");
    });
  }

  function handleDeleteAccount() {
    deleteAccount().then((response) => {
      if (response.data.code == 200) {
        setUser({ userName: null, isLoggedIn: false });
        localStorage.clear();
        toggleDeleteModal();
        toggleAccDeleted();
        setTimeout(() => {
          navigate("/");
        }, 5000);
      } else {
        toggleDeleteModal();
        setErrorMessage(response.data.message);
        toggleShowError();
      }

      //navigate("/");
    });
  }

  function handleFinishSubscription() {
    finishSubscription().then((res) => {
      setSubscriptionModalType(true);
      setSubscriptionModalText(res.data.message);
    });
  }
  {
    /**
  function passwordChangeSubmit(event) {
    const { oldPassword, newPassword, newPasswordRepeat } = userCredentials;
    if (oldPassword !== "" && newPassword !== "" && newPasswordRepeat !== "") {
      changePassword(oldPassword, newPassword, newPasswordRepeat).then(
        (response) => {
          if (response !== undefined && response.data.code === 200) {
            toggleSuccessModal();
          } else {
            setErrorMessage(response.data.message);
          }
        }
      );
      event.preventDefault();
    }
  }
  
  const handleCredentialChange = (e) => {
    const { value, id } = e.target;
    userCredentials[id] = value;
    setUserCredentials({ ...userCredentials });
  };
 */
  }
  function changeUserDetails(event) {
    const {
      name,
      surname,
      city,
      email,
      gsmno,
      address,
      indetityNo,
      iytPermission,
    } = userDetails;
    if (validator.isEmail(email)) {
      //var clearGsm = gsmno.split(" ", 1);
      changeUser(
        name,
        surname,
        city,
        email,
        gsmno,
        address,
        indetityNo,
        iytPermission
      ).then((response) => {
        if (response !== undefined && response.data.code === 200) {
          toggleSuccessModal();
        } else {
          setErrorMessage(response.data.message);
        }
      });
      event.preventDefault();
    }
  }

  function handleContactForm(event) {
    if (contactMessage === "") {
      return;
    }
    contactUs(contactMessage, contactSubject)
      .then((response) => {
        if (response !== undefined && response.status === 200) {
          setContactModalType(true);
          toggleContactModal();
          setContactMessage("");
          setRefreshMessageList(!refreshMessageList);
        } else {
          setContactModalType(false);
          toggleContactModal();
          setContactMessage("");
        }
      })
      .catch((err) => {
        setContactModalType(false);
        toggleContactModal();
      });

    event.preventDefault();
  }

  function toggleEdit() {
    setEdit(!edit);
  }

  useEffect(() => {
    getProfileImages().then((response) => {
      setProfiles(response.data);
    });
  }, []);

  function onAvatarClick() {
    toggleAvatarModal();
  }

  async function onAvatarSelect(imageUrl) {
    await addProfileImage(imageUrl);
    // refresh page
    window.location.reload();
  }

  return (
    <>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Hesabım</title>
        </Helmet>
      </div>

      <div className="user-details">
        <div
          className="container"
          style={{ marginTop: "15vh", marginBottom: "20vh" }}
        >
          <Tab.Container defaultActiveKey="hesabim">
            <Row style={{ fontFamily: "GilroyRegular", letterSpacing: "1px" }}>
              <Col md={3}>
                <Nav variant="pills" className="flex-column">
                  <NavUserNameBoxStyled>
                    <div>
                      <div>Merhaba,</div>
                      {userDetails && (
                        <div
                          style={{
                            fontSize: "20px",
                            fontWeight: "500",
                            marginBottom: "10px",
                          }}
                        >
                          {userDetails.name}
                        </div>
                      )}
                    </div>
                    {userDetails ? (
                      <img
                        onClick={onAvatarClick}
                        style={
                          avatarHover
                            ? {
                                marginBottom: 8,
                                width: 48,
                                height: 48,
                                opacity: 0.6,
                              }
                            : {
                                marginBottom: 8,
                                width: 48,
                                height: 48,
                              }
                        }
                        src={userDetails.imageUrl || "/assets/img/man.png"}
                        alt="profile"
                        onMouseEnter={() => setAvatarHover(true)}
                        onMouseLeave={() => setAvatarHover(false)}
                      />
                    ) : null}
                  </NavUserNameBoxStyled>
                  <NavMenuItemStyled>
                    <Nav.Link
                      eventKey="hesabim"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Icon src={profileIcon} alt="Profile Icon" width="16" />{" "}
                      <span>Hesap Ayarlarım</span>
                    </Nav.Link>
                  </NavMenuItemStyled>
                  <NavMenuItemStyled>
                    <Nav.Link
                      eventKey="abonelik-paketleri"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Icon src={packagesIcon} alt="Profile Icon" width="16" />{" "}
                      <span>
                        {userDetails.status === "INACTIVE"
                          ? "Paketler"
                          : "Abonelik Bilgilerim"}
                      </span>
                    </Nav.Link>
                  </NavMenuItemStyled>
                  {userDetails.status === "INACTIVE" ? null : (
                    <NavMenuItemStyled>
                      <Nav.Link
                        eventKey="fatura-bilgilerim"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Icon
                          src={billingfoIcon}
                          alt="Profile Icon"
                          width="16"
                        />{" "}
                        <span>Fatura Bilgilerim</span>
                      </Nav.Link>
                    </NavMenuItemStyled>
                  )}

                  <NavMenuItemStyled>
                    <Nav.Link
                      eventKey="sifre-degistir"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Icon src={chngPsw} alt="Profile Icon" width="16" />{" "}
                      <span>Şifre Değiştir</span>
                    </Nav.Link>
                  </NavMenuItemStyled>
                  <NavMenuItemStyled>
                    <Nav.Link
                      eventKey="iletisime-gec"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Icon src={contactUsIcon} alt="Profile Icon" width="16" />{" "}
                      <span>İletişime Geç</span>
                    </Nav.Link>
                  </NavMenuItemStyled>
                  <NavMenuItemStyled>
                    <Nav.Link
                      eventKey="sik-sorulan-sorular"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Icon src={faqIcon} alt="Profile Icon" width="16" />{" "}
                      <span>Sık Sorulan Sorular</span>
                    </Nav.Link>
                  </NavMenuItemStyled>
                  <NavMenuItemStyled>
                    <Nav.Link
                      onClick={logoutSubmit}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Icon src={logoutIcon} alt="Profile Icon" width="16" />{" "}
                      <span>Çıkış Yap</span>
                    </Nav.Link>
                  </NavMenuItemStyled>
                </Nav>
              </Col>
              <Col md={6}>
                <TabContent>
                  <Tab.Pane eventKey="hesabim">
                    <TabTitle style={styles.editTitle}>
                      <div>HESAP AYARLARIM</div>
                      <i
                        onClick={toggleEdit}
                        style={{
                          fontSize: 18,
                          paddingRight: 22,
                          color: edit ? "#12d6df" : "white",
                        }}
                        className={edit ? "bi bi-pencil-fill" : "bi bi-pencil"}
                      ></i>
                    </TabTitle>
                    <Line></Line>
                    <Form
                      onSubmit={changeUserDetails}
                      style={{ marginTop: "30px" }}
                    >
                      <Form.Group className="mb-3">
                        <Form.Label>İSİM SOYİSİM</Form.Label>
                        <FormControl
                          id="name"
                          value={userDetails.name || ""}
                          placeholder="İsim Soyisim"
                          onChange={handleChange}
                          disabled={!edit}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>ŞEHİR</Form.Label>
                        <FormSelect
                          id="city"
                          value={userDetails.city || "İstanbul"}
                          onChange={handleChange}
                          disabled={!edit}
                        >
                          {cities.map((city) => (
                            <option value={city.name} key={city.name}>
                              {city.name}
                            </option>
                          ))}
                        </FormSelect>
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label>E-POSTA ADRESİ</Form.Label>
                        <FormControl
                          id="email"
                          value={userDetails.email || ""}
                          placeholder="Eposta Adresin"
                          onChange={handleChange}
                          disabled
                        />
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label>TELEFON NUMARASI</Form.Label>
                        <FormControlMask
                          className="form-control"
                          type="text"
                          mask="(+\90) 999 999 99 99"
                          value={userDetails.gsmno || ""}
                          onChange={handleChange}
                          id="gsmno"
                          placeholder="Telefon Numaran"
                          minLength={10}
                          disabled={!edit}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label>TİCARİ İLETİ İZNİ</Form.Label>
                        <FormSelect
                          id="iytPermission"
                          value={userDetails.iytPermission || "ONAY"}
                          onChange={handleChange}
                          disabled={!edit}
                        >
                          <option value={"ONAY"} key={"ONAY"}>
                            İzin verildi.
                          </option>
                          <option value={"RET"} key={"RET"}>
                            İzin verilmedi.
                          </option>
                        </FormSelect>
                      </Form.Group>

                      {/*
                      <Form.Group className="mb-3">
                        <Form.Label>ABONELİK PAKETİ</Form.Label>
                        <InfoField>
                          {userDetails.product?.name || "-"}
                        </InfoField>
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label>ABONELİK DURUMU</Form.Label>
                        <InfoField>{userDetails.status || "-"}</InfoField>

                        {userDetails.status === "ACTIVE" ? (
                          ""
                        ) : (
                          <Link
                            to={
                              "/user/product-selection/" +
                              localStorage.getItem("guid") +
                              (isEdu ? "?epi=edu" : "")
                            }
                            style={{ color: "white", fontSize: "15px" }}
                          >
                            Abonelik Paketleri
                          </Link>
                        )}
                      </Form.Group>
                          */}
                      {/*   <Form.Group className="mb-3">
                      <Form.Label>İZİNLERİM</Form.Label>
                      <FormCheckInput className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="check1"
                          name="option1"
                          value=""
                        />
                        <Form.Label style={{ marginBottom: "20px" }}>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod
                        </Form.Label>
                      </FormCheckInput>
                      <FormCheckInput className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="check1"
                          name="option1"
                          value="something"
                        />
                        <Form.Label>
                          Lorem ipsum dolor sit amet, consectetur
                        </Form.Label>
                      </FormCheckInput>
                    </Form.Group> */}

                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <BootstrapButton
                          style={{ marginBottom: "15px", width: "50%" }}
                          className="btn btn-primary checkOutButton w-100"
                          type="submit"
                        >
                          KAYDET
                        </BootstrapButton>
                      </div>
                      <div>
                        <SubsFormButtons
                          style={{
                            marginBottom: "15px",
                            width: "100%",
                            border: "0px",
                            color: "#ffffff7d",
                          }}
                          className="btn btn-primary cancelButton "
                          type="button"
                          onClick={toggleDeleteModal}
                        >
                          Hesabımı Sil
                        </SubsFormButtons>
                      </div>

                      {/*
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          style={{ marginBottom: "15px", width: "49%" }}
                          className="btn btn-primary cancelButton "
                          type="button"
                          onClick={toggleFinishModal}
                        >
                          Abonelik Sonlandır
                        </Button>
                        <Button
                          style={{ marginBottom: "15px", width: "49%" }}
                          className="btn btn-primary cancelButton "
                          type="button"
                          onClick={toggleDeleteModal}
                        >
                          Hesabımı Sil
                        </Button>
                      </div>
                      */}
                    </Form>
                    {/*}
                    <TabTitle style={{ marginBottom: "15px" }}>
                      ŞİFRE DEĞİŞTİR
                    </TabTitle>
                    
                    <Form onSubmit={passwordChangeSubmit}>
                      <Form.Group className="mb-3">
                        <Form.Label>ESKİ ŞİFRE</Form.Label>
                        <FormControl
                          id="oldPassword"
                          value={userCredentials.oldPassword}
                          placeholder="Eski Şifre"
                          onChange={handleCredentialChange}
                          type="password"
                        />
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label>YENİ ŞİFRE</Form.Label>
                        <FormControl
                          id="newPassword"
                          value={userCredentials.newPassword}
                          placeholder="Yeni Şifre"
                          onChange={handleCredentialChange}
                          type="password"
                        />
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label>YENİ ŞİFRE TEKRAR</Form.Label>
                        <FormControl
                          id="newPasswordRepeat"
                          value={userCredentials.newPasswordRepeat}
                          placeholder="Yeni Şifre Tekrar"
                          onChange={handleCredentialChange}
                          type="password"
                        />
                      </Form.Group>

                      <p>{errorMessage}</p>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "30px",
                        }}
                      >
                        <Button
                          style={{ width: "50%" }}
                          className="btn btn-primary checkOutButton w-100"
                          type="submit"
                        >
                          KAYDET
                        </Button>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "20px",
                          fontSize: "14px",
                          letterSpacing: "1px",
                        }}
                      >
                        İPTAL ET
                      </div>
                    </Form>
                    */}
                  </Tab.Pane>
                  <Tab.Pane eventKey="fatura-bilgilerim">
                    <TabTitle>
                      <div>FATURA BİLGİLERİM</div>
                    </TabTitle>
                    <Line></Line>
                    <Form
                      onSubmit={changeUserDetails}
                      style={{ marginTop: "30px" }}
                    >
                      <Form.Group className="mb-3">
                        <Form.Label>TCK NO</Form.Label>
                        <FormControl
                          id="identityNo"
                          placeholder="TCKNO"
                          onChange={handleChange}
                          maxLength="11"
                          pattern="[0-9]{11}"
                          title="11 Haneli TCK NO girmelisiniz."
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>ADRES</Form.Label>
                        <FormControl
                          id="address"
                          placeholder="ADRES"
                          onChange={handleChange}
                          as="textarea"
                          style={{
                            color: "#dfdee2",
                            padding: "10px",
                            boxSizing: "border-box",
                            width: "100%",
                            height: "100px",
                            borderColor: "rgba(0,0,0,0.8)",
                          }}
                        />
                      </Form.Group>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <BootstrapButton
                          style={{ marginBottom: "15px", width: "50%" }}
                          className="btn btn-primary checkOutButton w-100"
                          type="submit"
                        >
                          KAYDET
                        </BootstrapButton>
                      </div>
                    </Form>
                  </Tab.Pane>
                  <Tab.Pane eventKey="iletisime-gec">
                    <TabTitle>
                      <div>İLETİŞİM FORMU</div>
                    </TabTitle>
                    <Line />
                    <Form onSubmit={handleContactForm}>
                      <Form.Group className="mb-3">
                        <Form.Label>KONU</Form.Label>
                        <FormSelect
                          id="subject"
                          defaultValue={subjects[0]}
                          onChange={(e) =>
                            setContactSubject(e.currentTarget.value)
                          }
                        >
                          {subjects.map((subject) => (
                            <option value={subject} key={subject}>
                              {subject}
                            </option>
                          ))}
                        </FormSelect>
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label style={{ marginTop: "5px" }}>
                          MESAJINIZ
                        </Form.Label>
                        <FormControl
                          id="message"
                          value={contactMessage}
                          onChange={(e) => setContactMessage(e.target.value)}
                          placeholder="Mesajınızı yazınız."
                          as="textarea"
                          style={{
                            color: "#dfdee2",
                            padding: "10px",
                            boxSizing: "border-box",
                            width: "100%",
                            height: "100px",
                            borderColor: "rgba(0,0,0,0.8)",
                          }}
                        />
                      </Form.Group>

                      <p>{errorMessage}</p>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <BootstrapButton
                          style={{ marginBottom: "15px", width: "50%" }}
                          className="btn btn-primary checkOutButton w-100"
                          type="submit"
                        >
                          KAYDET
                        </BootstrapButton>
                      </div>
                    </Form>
                    <Messages refreshList={refreshMessageList} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="sik-sorulan-sorular">
                    <TabTitle>
                      <div>SIK SORULAN SORULAR</div>
                    </TabTitle>
                    {sss &&
                      sss.map((e, index) => {
                        return (
                          <div key={index}>
                            <Line />
                            <div style={{ marginTop: "30px" }}>
                              <div style={{ fontSize: "18px" }}>{e.Q}</div>
                              <div style={{ marginTop: "10px" }}>{e.A}</div>
                            </div>
                          </div>
                        );
                      })}
                    <Line></Line>
                  </Tab.Pane>
                  <Tab.Pane eventKey="sifre-degistir">
                    <TabTitle>
                      <div>ŞİFRE DEĞİŞTİR</div>
                    </TabTitle>
                    <Line></Line>
                    <ChangePassword></ChangePassword>
                  </Tab.Pane>

                  <Tab.Pane eventKey="abonelik-paketleri">
                    <TabTitle>
                      {userDetails.status === "INACTIVE" ? (
                        <div>PAKETLER</div>
                      ) : (
                        <div>ABONELİK BİLGİLERİM</div>
                      )}
                    </TabTitle>
                    <Line></Line>
                    <MySubscription userDetails={userDetails} isEdu={isEdu} />
                  </Tab.Pane>
                </TabContent>
              </Col>
            </Row>
          </Tab.Container>
        </div>
        <HooxModal
          isShowing={isShowingSuccess}
          hide={toggleSuccessModal}
          title="BAŞARILI"
        >
          <p style={{ color: "#7b748c" }}>Bilgileriniz Güncellendi</p>
        </HooxModal>
        <HooxModal
          isShowing={contactModalVisible}
          hide={toggleContactModal}
          title="Bize Ulaşın"
          type={contactModalType ? HOOX_MODAL.SUCCESS : HOOX_MODAL.ERROR}
        >
          {" "}
          {contactModalType ? (
            <p>Mesajınız bize ulaştı. En kısa sürede dönüş yapacağız.</p>
          ) : (
            <p>Mesajınız gönderilemedi. Lütfen daha sonra tekrar deneyiniz.</p>
          )}
        </HooxModal>

        <HooxModal
          isShowing={isShowingAvatar}
          hide={toggleAvatarModal}
          title="Avatar Seç"
          onlyTitle
          type={HOOX_MODAL.INFO}
        >
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}>
            {profiles.map((profile, index) => {
              return (
                <div
                  style={{ display: "flex", justifyContent: "center" }}
                  key={index}
                >
                  <div
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "50%",
                      backgroundImage: `url(${profile.url})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      margin: "10px",
                    }}
                    onClick={() => {
                      onAvatarSelect(profile.url);
                      toggleAvatarModal();
                    }}
                  ></div>
                </div>
              );
            })}
          </div>
        </HooxModal>
      </div>

      <HooxModal
        isShowing={isShowingDelete}
        hide={toggleDeleteModal}
        title="Hesabımı Sil"
        type={HOOX_MODAL.INFO}
      >
        {" "}
        {
          <div>
            <p style={{ color: "#ffffff" }}>
              Hesabınızı silmek istediğinizden emin misiniz?
            </p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <BootstrapButton
                style={styles.deleteButton}
                className="btn btn-primary cancelButton "
                type="button"
                onClick={toggleDeleteModal}
              >
                İPTAL
              </BootstrapButton>
              <BootstrapButton
                style={styles.deleteButton}
                className="btn btn-primary checkOutButton "
                type="button"
                onClick={handleDeleteAccount}
              >
                SİL
              </BootstrapButton>
            </div>
          </div>
        }
      </HooxModal>

      <HooxModal
        isShowing={isShowingAccDeleted}
        hide={toggleAccDeleted}
        title="İŞLEM TAMAMLANDI"
      >
        <p style={{ color: "#ffffff" }}>
          Talebiniz doğrultusunda hesabınız silinmiştir. Hoox Anasayfa' ya
          yönlendiriliyorsunuz
        </p>
      </HooxModal>

      <HooxModal
        isShowing={isShowingError}
        hide={toggleShowError}
        title="İşlem Başarısız"
      >
        <p style={{ color: "#ffffff" }}>{errorMessage}</p>
      </HooxModal>

      <HooxModal
        isShowing={isShowingFinish}
        hide={toggleFinishModal}
        title="Aboneliğimi Sonlandır"
        type={subscriptionModalType ? HOOX_MODAL.SUCCESS : HOOX_MODAL.INFO}
      >
        {" "}
        {
          <div>
            <p style={{ color: "#7b748c" }}>{subscriptionModalText}</p>
            {subscriptionModalType ? null : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <BootstrapButton
                  style={styles.deleteButton}
                  className="btn btn-primary cancelButton "
                  type="button"
                  onClick={toggleFinishModal}
                >
                  İPTAL
                </BootstrapButton>
                <BootstrapButton
                  style={styles.deleteButton}
                  className="btn btn-primary checkOutButton "
                  type="button"
                  onClick={handleFinishSubscription}
                >
                  SONLANDIR
                </BootstrapButton>
              </div>
            )}
          </div>
        }
      </HooxModal>
    </>
  );
}
export default UserDetails;

const styles = {
  deleteButton: {
    marginBottom: "15px",
    width: "35%",
    marginRight: "5px",
    marginLeft: "5px",
  },
  editTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
};
