import { Button, Col, Container, Row } from "react-bootstrap";
import Header from "../components/Header";
import HooxModal, { HOOX_MODAL } from "../components/HooxModal";
import { useNavigate } from "react-router";
import { useEffect } from "react";

function PaymentFail() {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/user/login");
    }, 5000);
  }, []);

  return (
    <Container>
      <Header />
      <Row style={{ margin: "10px", textAlign: "center" }}>
        <Col style={{ margin: "10px", textAlign: "center" }}>
          <h1
            style={{
              fontSize: "3em",
              marginBottom: "20px",
              marginTop: "20%",
              color: "#12d6df",
            }}
          >
            İşlem Başarısız
          </h1>{" "}
          <p style={{ fontSize: "1.5em" }}>
            {" "}
            Ödeme işleminiz başarısız olmuştur.
          </p>
        </Col>
      </Row>
      <Row style={{ marginTop: "15%", textAlign: "center" }}>
        <Col>
          <p>Giriş sayfasına yönlendiriliyorsunuz.</p>
          <Button variant="secondary" onClick={() => navigate("/user/login")}>
            Kapat
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
export default PaymentFail;
