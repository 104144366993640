import React, { useEffect, useState } from "react";
import { Form, Button, FormSelect } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import useModal from "../hooks/useModal";
import {
  deleteAccount,
  finishSubscription,
  getCardList,
  verifySubscription,
} from "../../service/customerService";
import HooxModal, { HOOX_MODAL } from "../HooxModal";
import { useAuth } from "../AuthContext";
import { getProducts } from "../../service/contentService";
import { ProductPrice } from "../../pages/ProductSelection/ProductSelection.styles";
import Status from "../commons/CustomerConsts";
import CardListDialog from "./CardListDialog";

export const InfoField = styled.p`
  display: block;
  background-color: rgb(0 7 58);
  border-radius: 7px;
  border: 1px solid rgba(18 214 223 / 30%);
  width: 100%;
  padding: 10px;
`;

export const SubsFormButtons = styled(`Button`)`
  background-color: transparent;
  border-radius: 7px;
  border: 1px solid rgba(18 214 223 / 30%);
  &:hover {
    background-color: #bface282;
  }

  &:disabled {
    background-color: #40456b;
    border: 1px solid rgb(216 219 223 / 35%);
    color: rgb(216 219 223 / 35%);
  }
`;

export const ProductAreaTitle = styled.div`
  display: block;
  background-color: transparent;
  width: 100%;
  font-size: 18px;
  font-family: GilroyBold;
  margin-bottom: 20px;
`;

export const ProductAreaItem = styled.div`
  display: block;
  background-color: transparent;
  width: 100%;
  font-size: 13px;
  font-family: GilroyLight;
  margin-bottom: 10px;
`;

export const ProductAreaDesc = styled.ul`
  list-style-type: disc;
  //padding-left: 0px;
  li {
    color: white; /* Metin rengi beyaz yap */
    line-height: 20px;
  }

  /* Yuvarlak noktaların (bullet point'lerin) rengini ayarla */
  li::marker {
    color: #bc00bc;
  }
`;

export default function MySubscription(props) {
  //const { isShowing: isShowingDelete, toggle: toggleDeleteModal } = useModal();
  const { isShowing: isShowingFinish, toggle: toggleFinishModal } = useModal();
  const { isShowing: isShowingAccDeleted, toggle: toggleAccDeleted } =
    useModal();

  const [subscriptionModalType, setSubscriptionModalType] = useState(false);
  const [subscriptionModalText, setSubscriptionModalText] = useState(
    "Aboneliğinizi iptal etmek istediğinize emin misiniz?"
  );

  const [product, setProduct] = useState(null);
  const [products, setProducts] = useState([]);
  const [productDescItems, setProductDescItems] = useState([]);

  //const { user } = useAuth();
  const { user, setUser } = useAuth();
  const navigate = useNavigate();

  const { isShowing: isShowingSuccess, toggle: toggleSuccessModal } =
    useModal();

  const { isShowing: isShowingFail, toggle: toggleFailModal } = useModal();

  const {
    isShowing: isShowingPackageSelection,
    toggle: togglePackageSelectionModal,
  } = useModal();

  const [failMessage, setFailMessage] = useState(null);

  const [isLoadingCardList, setIsLoadingCardList] = useState(false);
  const [cardList, setCardList] = useState([]);

  const { isShowing: isShowingCardList, toggle: toggleCardListModal } =
    useModal();

  //let { guid: customerGuid } = useParams();
  /*
  function handleDeleteAccount() {
    deleteAccount().then(() => {
      setUser({ userName: null, isLoggedIn: false });
      localStorage.clear();
      toggleDeleteModal();
      toggleAccDeleted();
      setTimeout(() => {
        navigate("/");
      }, 5000);
      //navigate("/");
    });
  }
*/

  function handleFinishSubscription() {
    finishSubscription().then((res) => {
      if (res.data.code === 200) {
        setSubscriptionModalType(true);
        setSubscriptionModalText(res.data.message);
      } else {
        toggleFinishModal();
        setFailMessage(res.data.message);
        toggleFailModal();
      }
    });
  }

  useEffect(() => {
    getProducts().then((response) => {
      // TODO: select activeIndex logic
      if (response.data && response.data.length > 0) {
        setProducts(response.data);
        // İlk ürünü alıp products state'ine ekleyin
        //console.log("[response.data[0]:", response.data[0]);
        setProduct(response.data[0]);
      }
    });
  }, []);

  useEffect(() => {
    setDescription();
  }, [product]);

  function toggleRenewPayment() {
    //console.log("KK ekranı");
    //console.log("userDetails.product:", props.userDetails.product);
    navigate(
      "/payment-direct/" +
        localStorage.getItem("guid") +
        "/" +
        props.userDetails.product?.externalId
    );
  }

  function setSelectedProduct(e) {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const selectedProductId = parseInt(selectedOption.getAttribute("id"));
    //const selectedProductValue = selectedOption.getAttribute("value");

    const selectedProductFromList = products.find(
      (product) => product.id === selectedProductId
    );
    setProduct(selectedProductFromList);
  }

  function setDescription() {
    if (product) {
      const stringParts = product?.description
        .split("|")
        .map((part) => part.trim());
      setProductDescItems(stringParts);
    }
  }

  const startSubscription = async () => {
    // add verify Subscription
    if (product && product?.externalId) {
      verifySubscription(product?.externalId, user.guid).then((response) => {
        if (response?.code === 200) {
          navigate("/payment-direct/" + user.guid + "/" + product?.externalId);
        } else {
          setFailMessage(response?.message);
          toggleFailModal();
        }
      });
    } else {
      togglePackageSelectionModal();
    }
  };

  function obtainCardData() {
    setIsLoadingCardList(true);

    getCardList()
      .then((response) => {
        if (response.code === 200) {
          setCardList(response.data);
          toggleCardListModal();
        } else {
          setFailMessage(response.message);
          toggleFailModal();
        }
        setIsLoadingCardList(false);
      })
      .catch((error) => console.log(error));

    /*
    navigate(
      "/payment-direct/" +
        localStorage.getItem("guid") +
        "/" +
        props.userDetails.product?.externalId
    );
    */
  }

  return (
    <>
      <Form onSubmit={startSubscription} style={{ marginTop: "30px" }}>
        {props.userDetails.status === "INACTIVE" ? (
          ""
        ) : (
          <Form.Group className="mb-3">
            <Form.Label>ABONELİK PAKETİ</Form.Label>
            <InfoField>{props.userDetails.product?.name || " - "}</InfoField>
          </Form.Group>
        )}

        {props.userDetails.subscriptionStatus && (
          <Form.Group className="mb-3">
            <Form.Label>ABONELİK DURUMU</Form.Label>
            <InfoField>
              {props.userDetails.subscriptionStatus === "ACTIVE" ? "Aktif" : ""}
              {props.userDetails.subscriptionStatus === "INACTIVE"
                ? "Aboneliğiniz bulunmamaktadır"
                : ""}
              {props.userDetails.subscriptionStatus === "WAITING_PAYMENT"
                ? "Ödeme yenileme gerekli"
                : ""}
            </InfoField>
          </Form.Group>
        )}
        {props.userDetails.subscriptionStatus === "WAITING_PAYMENT" ? (
          <>
            <Form.Group className="mb-3">
              <Form.Label>ABONELİK SONLANMA TARİHİ</Form.Label>
              <InfoField>
                {props.userDetails.subscriptionEndDate
                  ? props.userDetails.subscriptionEndDate
                  : "-"}
              </InfoField>
            </Form.Group>
          </>
        ) : (
          ""
        )}

        {props.userDetails.subscriptionStatus === "ACTIVE" ? (
          <>
            <Form.Group className="mb-3">
              <Form.Label>
                {props.userDetails.subscriptionEndDate
                  ? "ABONELİK SONLANMA TARİHİ"
                  : "ABONELİK YENİLENME TARİHİ"}
              </Form.Label>
              <InfoField>
                {props.userDetails?.subscriptionRenewalDate ||
                  props.userDetails?.subscriptionEndDate ||
                  " - "}
              </InfoField>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>PAKET ÜCRETİ</Form.Label>
              <InfoField>
                {props.userDetails.subscriptionPrice + " .TL" || "-"}
              </InfoField>
            </Form.Group>
          </>
        ) : (
          ""
        )}

        {props.userDetails.status === "INACTIVE" ? (
          <>
            <Form.Group className="mb-3">
              <Form.Label>ABONELİK PAKETLERİ</Form.Label>
              <FormSelect
                id="packages"
                defaultValue={products[0]}
                onChange={(e) => setSelectedProduct(e)}
                className="FormSelect"
              >
                {products.map((prd) => (
                  <option value={prd.name} key={prd.id} id={prd.id}>
                    {prd.name}
                  </option>
                ))}
              </FormSelect>
            </Form.Group>

            <ProductAreaTitle>SEÇİLEN PAKET ÖZELLİKLERİ</ProductAreaTitle>
            <ProductAreaItem>{product?.name}</ProductAreaItem>
            <div style={{ marginBottom: "20px" }}>
              <ProductAreaDesc>
                {productDescItems.map((part, index) => (
                  <li style={{ marker: "red" }} key={index}>
                    {part}
                  </li>
                ))}
              </ProductAreaDesc>
            </div>
            {product?.externalId == "edu" ? (
              <div>Sadece .edu uzantılı mail adresi ile üye olabilirsiniz.</div>
            ) : (
              ""
            )}

            <ProductPrice style={{ fontSize: "28px", marginBottom: "30px" }}>
              {
                product?.priceWithTax
                  .toLocaleString("tr-TR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                  .split(",")[0]
              }
              ,
              <small>
                {
                  product?.priceWithTax
                    .toLocaleString("tr-TR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                    .split(",")[1]
                }
              </small>{" "}
              TL.
            </ProductPrice>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                style={{ marginBottom: "15px", width: "50%" }}
                className="btn btn-primary checkOutButton w-100"
                onClick={(e) => startSubscription()}
                //disabled={false} //user.email must end with .edu.tr and selected product if edu.
              >
                ABONE OL
              </Button>
            </div>
          </>
        ) : (
          ""
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {props.userDetails.subscriptionStatus === Status.WAITING_PAYMENT ? (
            <SubsFormButtons
              style={{
                marginBottom: "15px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
              className="btn btn-primary cancelButton "
              type="button"
              onClick={obtainCardData}
              disabled={isLoadingCardList}
            >
              {isLoadingCardList ? (
                <div className="spinner"></div>
              ) : (
                "Ödeme Yenile"
              )}
            </SubsFormButtons>
          ) : (
            <SubsFormButtons
              style={{ marginBottom: "15px", width: "100%" }}
              className="btn btn-primary cancelButton "
              type="button"
              onClick={toggleFinishModal}
              disabled={
                !(props.userDetails.status === Status.ACTIVE) ||
                (props.userDetails.subscriptionEndDate && "true")
              }
            >
              Abonelik Sonlandır
            </SubsFormButtons>
          )}

          {/*

          <SubsFormButtons
            style={{ marginBottom: "15px", width: "100%" }}
            className="btn btn-primary cancelButton "
            type="button"
            onClick={toggleFinishModal}
            disabled={!(props.userDetails.status === "ACTIVE")}
          >
            Abonelik Sonlandır
          </SubsFormButtons>



        
          <SubsFormButtons
            style={{ marginBottom: "15px", width: "49%" }}
            className="btn btn-primary cancelButton "
            type="button"
            onClick={toggleDeleteModal}
          >
            Hesabımı Sil
          </SubsFormButtons>
          */}
        </div>
      </Form>

      {/*
      <HooxModal
        isShowing={isShowingDelete}
        hide={toggleDeleteModal}
        title="Hesabımı Sil"
        type={HOOX_MODAL.INFO}
      >
        {" "}
        {
          <div>
            <p style={{ color: "#ffffff" }}>
              Hesabınızı sildiğinizde kullanıcı bilgilernizle giriş
              yapamayacaksınız. Hesabınızı silmek istediğinizden emin misiniz?
            </p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                style={styles.deleteButton}
                className="btn btn-primary cancelButton "
                type="button"
                onClick={toggleDeleteModal}
              >
                İPTAL
              </Button>
              <Button
                style={styles.deleteButton}
                className="btn btn-primary checkOutButton "
                type="button"
                onClick={handleDeleteAccount}
              >
                SİL
              </Button>
            </div>
          </div>
        }
      </HooxModal>
      */}

      <HooxModal
        isShowing={isShowingAccDeleted}
        hide={toggleAccDeleted}
        title="İŞLEM TAMAMLANDI"
      >
        <p style={{ color: "#ffffff" }}>
          Talebiniz doğrultusunda hesabınız silinmiştir. Hoox Anasayfa' ya
          yönlendiriliyorsunuz
        </p>
      </HooxModal>

      <HooxModal
        isShowing={isShowingFinish}
        hide={toggleFinishModal}
        title="Aboneliğimi Sonlandır"
        type={subscriptionModalType ? HOOX_MODAL.SUCCESS : HOOX_MODAL.INFO}
      >
        {" "}
        {
          <div>
            <p style={{ color: "#7b748c" }}>{subscriptionModalText}</p>
            {subscriptionModalType ? null : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  style={styles.deleteButton}
                  className="btn btn-primary cancelButton "
                  type="button"
                  onClick={toggleFinishModal}
                >
                  İPTAL
                </Button>
                <Button
                  style={styles.deleteButton}
                  className="btn btn-primary checkOutButton "
                  type="button"
                  onClick={handleFinishSubscription}
                >
                  SONLANDIR
                </Button>
              </div>
            )}
          </div>
        }
      </HooxModal>

      <HooxModal
        isShowing={isShowingPackageSelection}
        hide={togglePackageSelectionModal}
        title="Paket Seçimi"
        type={HOOX_MODAL.INFO}
      >
        <p style={{ color: "#ffffff" }}>
          İlerlemek için bir paket seçmelisiniz.
        </p>
      </HooxModal>

      <HooxModal
        isShowing={isShowingFail}
        hide={toggleFailModal}
        title="İşlem Başarısız"
        type={HOOX_MODAL.INFO}
      >
        <p style={{ color: "#ffffff" }}>{failMessage}</p>
      </HooxModal>

      <CardListDialog
        isShowing={isShowingCardList}
        hide={toggleCardListModal}
        data={cardList}
        userDetails={props.userDetails}
      ></CardListDialog>
    </>
  );
}

const styles = {
  deleteButton: {
    marginBottom: "15px",
    width: "35%",
    marginRight: "5px",
    marginLeft: "5px",
  },
  editTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
};
