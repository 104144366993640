import { useEffect } from "react";

let temp = 0;

function VideoStatusUsingApi({ videoRef, isAPIReady, setPlayer }) {
  useEffect(() => {
    if (!isAPIReady) return;
    if (!videoRef) return;
    const player = new window.VdoPlayer(videoRef);
    window.player1 = player;
    player.video.addEventListener("timeupdate", () => {
      if (temp === 0) {
        player.video.currentTime = 0;
        temp = temp + 1;
      }
    });
    player.video.addEventListener("ended", () => {
      player.video.pause();
      player.video.currentTime = 0;
    });
    setPlayer(player);
    window.player1 = player;
  }, [isAPIReady, videoRef]);

  return null;
}

export default VideoStatusUsingApi;
