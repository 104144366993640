import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import { Button, Col, Container, Row } from "react-bootstrap";
import Header from "../components/Header";
import { validateSubscription } from "../service/customerService";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { logout } from "../service/contentService";
import { useAuth } from "../components/AuthContext";

const PaymantFallback = () => {
  const type = useParams().type;
  const navigate = useNavigate();
  const titleText = () => {
    if (type === "success") {
      return "Ödeme işleminiz başarıyla tamamlandı.";
    } else if (type === "fail") {
      return "Ödeme işleminiz başarısız oldu.";
    } else {
      return "Ödeme sırasında bir hata oluştu";
    }
  };

  const [isSubscriptionExist, setIsSubscriptionExist] = useState(false);
  const [userName, setUserName] = useState("");
  const [packageName, setPackageName] = useState("");
  const [packageId, setPackageId] = useState("");
  const [packagePrice, setPackagePrice] = useState("");
  const [message, setMessage] = useState("");
  const [transactionId, setTransactionId] = useState("");

  const { user } = useAuth();
  const { isLoggedIn } = user;
  const { setUser } = useAuth();

  const queryParams = new URLSearchParams(window.location.search);

  const customerGuid = queryParams.get("gc");
  const packageExternalId = queryParams.get("epi");
  const ccParam = queryParams.get("cc");

  useEffect(() => {
    console.log("ccParam:", ccParam);
    setTimeout(() => {
      if (isLoggedIn) {
        if (type === "success") {
          if (ccParam != 1) {
            logout().then(() => {
              setUser({ userName: null, isLoggedIn: false });
              localStorage.clear();
              navigate("/user/login");
            });
          } else {
            navigate("/user-details");
          }
        } else {
          navigate("/home");
        }
      }
    }, 5000);
  }, []);

  useEffect(() => {
    if (type === "success") {
      validateSubscription(packageExternalId, customerGuid).then((response) => {
        //console.log("validateSubscription.response:", response);
        if (response?.code === 200) {
          setIsSubscriptionExist(response.data?.isExist);
          if (response.data?.isExist) {
            setUserName(response.data?.customerName || "");
            setPackageName(response.data?.productName);
            setPackageId(response.data?.productId || "");
            setPackagePrice(response.data?.productPrice || "");
            setTransactionId(response.data?.transaction_id || "");
            //datalayerPush();
          }
        } else {
          //console.log("message:", response?.message);
          setMessage(response?.message || "");
        }

        // TODO: select activeIndex logic
      });
    }
  }, [customerGuid]);

  useEffect(() => {
    if (packageExternalId === "edu") {
      <script>esk('track', 'ogrenci');</script>;
    }
    <script>esk('track', 'ikicihaz');</script>;
  }, [packageExternalId]);

  useEffect(() => {
    if (transactionId) {
      //console.log("productPrice:", packagePrice);
      //console.log("transactionId:", transactionId);
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "purchase",
        ecommerce: {
          currency: "TRY",
          value: packagePrice,
          transaction_id: transactionId,
          items: [
            {
              item_name: packageName,
              item_id: packageId,
              price: packagePrice,
              quantity: "1",
            },
          ],
        },
      });
    }
  }, [transactionId]);

  return (
    <>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Ödeme {type === "success" ? "Başarılı" : "Başarısız"}</title>
          <meta
            name="description"
            content="Muud medya dijital yayıncılığın Rtük onaylı tescilli markası olan Hoox.tv yeni nesil bir dijital video izleme platformdur. "
          />
        </Helmet>
      </div>

      <Container>
        <Header />
        <Row style={{ margin: "10px", textAlign: "center" }}>
          <Col style={{ textAlign: "center" }}>
            <h1
              style={{
                fontSize: "3em",
                marginBottom: "20px",
                marginTop: "15%",
                color: "#12d6df",
              }}
            >
              {titleText()}
            </h1>{" "}
            {isSubscriptionExist && (
              <p style={{ fontSize: "1.5em" }}>
                Merhaba {userName}, {packageName} paket aboneliğin başarılı
                şekilde
                {ccParam != 1 ? " tamamlandı." : " güncellendi."}
              </p>
            )}
            {message && <p style={{ fontSize: "1.5em" }}>{message}</p>}
            {type === "success" ? (
              <>
                <p style={{ fontSize: "1.5em" }}>
                  {ccParam != 1
                    ? "Giriş yaparak birbirinden eğlenceli içeriklerimize ulaşabilirsin."
                    : "Aboneliğiniz devam ediyor."}
                </p>
              </>
            ) : (
              <>
                <p style={{ fontSize: "1.5em" }}>
                  {ccParam != 1 ? "Abonelik işleminiz tamamlanamadı." : ""}
                </p>
              </>
            )}
          </Col>
        </Row>
        <Row style={{ marginTop: "15%", textAlign: "center" }}>
          <Col>
            {type === "success" ? (
              <>
                <p>
                  {ccParam != 1
                    ? "Giriş sayfasına yönlendiriliyorsunuz.."
                    : "Hesabım sayfasına yönlendiriliyorsunuz."}
                </p>
                <Button
                  variant="secondary"
                  onClick={() =>
                    navigate(ccParam != 1 ? "/user/login" : "/user-details")
                  }
                >
                  Kapat
                </Button>
              </>
            ) : (
              <>
                <p>Ana sayfaya yönlendiriliyorsunuz.</p>
                <Button variant="secondary" onClick={() => navigate("/home")}>
                  Kapat
                </Button>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PaymantFallback;
