// CustomerConsts.jsx

const Status = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  WAITING_PAYMENT: "WAITING_PAYMENT",
  SUSPENDED: "SUSPENDED",
};

export default Status;
